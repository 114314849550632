<template>
  <v-chip
    v-bind="$attrs"
    v-on="$listeners"
    :color="chipRecordStateColor(record)"
    :text-color="chipRecordStateTextColor(record)"
  >
    <v-avatar left v-if="chipRecordHasAttributeIcon(record)">
      <v-icon :color="chipRecordAttributeIconColor(record)">{{
        chipRecordAttributeIcon(record)
      }}</v-icon>
    </v-avatar>

    <slot>
      {{ text }}
    </slot>
  </v-chip>
</template>

<script>
// model
import { recordState } from "@/model/record/recordModel";

// mixins
import { chippableRecordMixin } from "@/mixins/shared/record/chippableRecordMixin";

export default {
  inheritAttrs: false,
  name: "RecordStateChip",
  mixins: [chippableRecordMixin],
  props: {
    record: null,
    showRecordType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    stateId() {
      return this.record?.stateId ?? recordState.new;
    },
    text() {
      return this.showRecordType
        ? `${this.record?.state} ${this.record.recordType}`
        : this.record?.state;
    }
  }
};
</script>
